import "./App.scss";
import "utils/sentry";

import * as Sentry from "@sentry/react";
import NoSleep from "@uriopass/nosleep.js";
import Cropper from "components/Cropper/Cropper";
import FilesList from "components/FilesList/FilesList";
import Footer from "components/Footer/Footer";
import GeneralData from "components/GeneralData/GeneralData";
import Header from "components/Header/Header";
import Info from "components/Info/Info";
import { API_URL } from "constants/urls";
import useConfirmTabClose from "hooks/useConfirmTabClose";
import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import * as appReducer from "reducers/useAppReducer";

function App() {
  const noSleep = new NoSleep();
  const [appState, appDispatch] = appReducer.useAppReducer();
  const { files, login, sending, success, surname } = appState;

  const shouldAskForConfirmOnExit = !!files.length || sending;
  useConfirmTabClose(shouldAskForConfirmOnExit);

  const sendImages = React.useCallback(async () => {
    appDispatch({ payload: true, type: appReducer.SET_SENDING });
    try {
      noSleep.enable();
    } catch (noSleepError) {
      Sentry.captureException(noSleepError);
    }

    let response: Response;
    try {
      response = await fetch(API_URL, {
        body: JSON.stringify({ files, login, surname }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
    } catch (e) {
      try {
        noSleep.disable();
      } catch (noSleepError) {
        Sentry.captureException(noSleepError);
      }
      appDispatch({ payload: false, type: appReducer.SET_SENDING });
      appDispatch({ payload: false, type: appReducer.SET_SUCCESS });
      Sentry.captureException(e);
      return;
    }

    try {
      noSleep.disable();
    } catch (noSleepError) {
      Sentry.captureException(noSleepError);
    }
    appDispatch({ payload: false, type: appReducer.SET_SENDING });
    if (!response.ok) {
      appDispatch({ payload: false, type: appReducer.SET_SUCCESS });
      const error = new Error("Server upload files error");
      Sentry.captureException(error, scope => scope.setExtra("response", response));
      return;
    }

    const jsonResponse = await response.json();
    if (!jsonResponse.success) {
      appDispatch({ payload: false, type: appReducer.SET_SUCCESS });
      const error = new Error("Response parsing error");
      Sentry.captureException(error, scope => scope.setExtra("response", response));
      return;
    }
    appDispatch({ payload: true, type: appReducer.SET_SUCCESS });
  }, [login, surname, files, noSleep]);

  return (
    <div className="App">
      <Header />
      <main>
        <Container>
          <Info />
          {sending ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Wysyłanie...</span>
            </Spinner>
          ) : success == null ? (
            <>
              <GeneralData appState={appState} appDispatch={appDispatch} sendImages={sendImages} />
              <FilesList files={files} appDispatch={appDispatch} />
              <hr style={{ margin: 20 }} />
              <Cropper appDispatch={appDispatch} />
            </>
          ) : (
            <Row>{success ? "Zdjęcia zostały wysłane." : "Wystąpił błąd podczas wysyłania zdjęć."}</Row>
          )}
        </Container>
      </main>
      <Footer />
    </div>
  );
}

export default App;
