import React from "react";

const confirmationMessage = "Zdjęcia nie zostały wysłane. Wyjść i utracić wszystkie zmiany?";

function useConfirmTabClose(isUnsafeTabClose: boolean) {
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isUnsafeTabClose) {
        // In most modern browsers there is no way to set custom message
        return (event.returnValue = confirmationMessage);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isUnsafeTabClose]);
}

export default useConfirmTabClose;
