import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { isMobileDevice } from "./mobileDetection";

const isMobile = isMobileDevice(window);
// Sentry integrations
const integrations = [
  new Integrations.BrowserTracing(),
  new Sentry.Integrations.Breadcrumbs({
    console: false, // console logging
    dom: false, // DOM interactions, i.e. clicks/typing
    fetch: true, // Fetch API
    history: false, // History API
    sentry: true, // sentry events
    xhr: true, // XMLHttpRequest
  }),
];

Sentry.init({
  dsn: "https://789080d3cb3342e3807ecfd34338b2b2@o25575.ingest.sentry.io/6073234",
  integrations,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Sentry.setTags({
  isMobile,
});
