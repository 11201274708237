import * as React from "react";

const Footer = () => {
  const year = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <footer>
      <div>
        ©{year} Made with&nbsp;
        <span role="img" aria-label="love">
          ❤️
        </span>
        &nbsp;by 2you
      </div>
      <small>v{process.env.REACT_APP_VERSION}</small>
    </footer>
  );
};

export default Footer;
