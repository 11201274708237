import { FinalImage } from "types";
import { useImmerReducer } from "use-immer";
import { createAction } from "utils/reducersHelpers";
import { InferValueTypes } from "utils/types";

export const ADD_FILE = "@app/ADD_FILE";
export const DELETE_FILE = "@app/DELETE_FILE";
export const SET_LOGIN = "@app/SET_LOGIN";
export const SET_SENDING = "@app/SET_SENDING";
export const SET_SUCCESS = "@app/SET_SUCCESS";
export const SET_SURNAME = "@app/SET_SURNAME";

export const addFile = createAction<typeof ADD_FILE, FinalImage>(ADD_FILE);
export const deleteFile = createAction<typeof DELETE_FILE, string>(DELETE_FILE);
export const setLogin = createAction<typeof SET_LOGIN, string>(SET_LOGIN);
export const setSending = createAction<typeof SET_SENDING, boolean>(SET_SENDING);
export const setSuccess = createAction<typeof SET_SUCCESS, boolean>(SET_SUCCESS);
export const setSurname = createAction<typeof SET_SURNAME, string>(SET_SURNAME);

const actions = {
  addFile,
  deleteFile,
  setLogin,
  setSending,
  setSuccess,
  setSurname,
};

export type Action = ReturnType<InferValueTypes<typeof actions>>;

export interface State {
  files: FinalImage[];
  login: string;
  sending: boolean;
  success?: boolean;
  surname: string;
}

const initialState: State = {
  files: [],
  login: "",
  sending: false,
  success: undefined,
  surname: "",
};

function reducer(draft: State, action: Action) {
  switch (action.type) {
    case ADD_FILE:
      draft.files.push(action.payload);
      return;
    case DELETE_FILE:
      draft.files = draft.files.filter(({ id }) => id !== action.payload);
      return;
    case SET_LOGIN:
      draft.login = action.payload;
      return;
    case SET_SENDING:
      draft.sending = action.payload;
      return;
    case SET_SUCCESS:
      draft.success = action.payload;
      return;
    case SET_SURNAME:
      draft.surname = action.payload;
      return;
  }
}

export function useAppReducer() {
  return useImmerReducer(reducer, initialState);
}

export default useAppReducer;
