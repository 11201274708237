import "react-image-crop/dist/ReactCrop.css";
import "./styles.scss";

import { useThrottledFn, useWindowResize } from "beautiful-react-hooks";
import React from "react";
import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { MdAddBox, MdCropRotate, MdIndeterminateCheckBox } from "react-icons/md";
import ReactCrop, { Crop } from "react-image-crop";
import * as appReducer from "reducers/useAppReducer";
import { v4 as uuidv4 } from "uuid";

const VALID_TYPES = ["image/jpeg", "image/png"];
const MIN_SIZE = 1500;
const ASPECT_RATIO = 3 / 2;
const DEFAULT_STATE: Partial<Crop> = {
  height: undefined,
  unit: "%",
  width: 100,
  x: 0,
  y: 0,
};

interface Props {
  appDispatch: React.Dispatch<appReducer.Action>;
}

function Cropper({ appDispatch }: Props) {
  const [upImg, setUpImg] = React.useState<string>("");
  const [fileType, setFileType] = React.useState<string>("");

  // Photo attributes
  const [photoName, setPhotoName] = React.useState<string>("");
  const [quantity, setQuantity] = React.useState<string>("1");
  const [fileName, setFileName] = React.useState<string>("");
  const [b64Image, setB64Image] = React.useState<string>("");

  const imgRef = React.useRef<any>(null);
  const [crop, setCrop] = React.useState<Partial<Crop>>({
    aspect: ASPECT_RATIO,
    ...DEFAULT_STATE,
  });

  const clearImage = React.useCallback(() => {
    setUpImg("");
    setFileType("");
    setPhotoName("");
    setQuantity("1");
    setFileName("");
    setB64Image("");
  }, []);

  React.useEffect(() => {
    if (!upImg) {
      setCrop({
        aspect: ASPECT_RATIO,
        ...DEFAULT_STATE,
      });
    }
  }, [upImg]);

  const addCurrentImage = React.useCallback(() => {
    appDispatch({ payload: { b64Image, fileName, id: uuidv4(), photoName, quantity }, type: appReducer.ADD_FILE });
    clearImage();
  }, [b64Image, fileName, photoName, quantity, clearImage]);

  const onSelectFile = React.useCallback(e => {
    const isFileLoaded = e.target.files && e.target.files.length === 1;
    if (!isFileLoaded) {
      alert("Nie załadowano pliku.");
      return;
    }
    const file = e.target.files[0];
    const isValid = VALID_TYPES.includes(file.type);
    if (!isValid) {
      alert(`Zły format pliku. Dozwolone: ${VALID_TYPES.join(", ")}`);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setUpImg(String(reader.result));
      setFileName(file.name);
      setFileType(file.type);
    });
    reader.readAsDataURL(file);
  }, []);

  const prepareCrop = React.useCallback(
    async crop => {
      if (!imgRef.current || !crop.width || !crop.height) {
        return;
      }
      const canvas = document.createElement("canvas");
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(
          imgRef.current,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }

      return new Promise<void>((resolve, reject) => {
        const b64Image = canvas.toDataURL(fileType);
        if (!b64Image) {
          reject(new Error("Canvas is empty"));
          return;
        }
        setB64Image(b64Image);
        resolve();
      });
    },
    [fileType]
  );

  const handleRotateClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setCrop(state => ({
      ...state,
      aspect: state?.aspect ? 1 / state?.aspect : undefined,
      ...DEFAULT_STATE,
    }));
  }, []);

  const onLoad = React.useCallback(
    img => {
      if (img.naturalWidth < MIN_SIZE || img.naturalHeight < MIN_SIZE) {
        alert(
          `Jakość zdjęcia jest za niska i nie nadaje się do wykorzystania. Minimalna rozdzielczość to ${MIN_SIZE}px x ${MIN_SIZE}px Dodaj inne zdjęcie.`
        );
        clearImage();
        return;
      }
      imgRef.current = img;

      setCrop(state => ({
        ...state,
        ...DEFAULT_STATE,
      }));
    },
    [clearImage]
  );

  useWindowResize(
    useThrottledFn(
      () => {
        setCrop(state => ({
          ...state,
          ...DEFAULT_STATE,
        }));
      },
      // TODO: Broken useThrottledFn types
      100,
      {
        leading: false,
        trailing: true,
      },
      []
    )
  );

  return (
    <div className="cropper-app">
      <Row className="panel">
        {upImg ? (
          <>
            <Col md={4} style={{ padding: 5 }}>
              <Button variant="warning" onClick={clearImage}>
                <MdIndeterminateCheckBox /> Anuluj dodawanie zdjęcia
              </Button>
            </Col>
            <Col md={4} style={{ padding: 5 }}>
              <Button variant="success" disabled={!photoName || !quantity} onClick={addCurrentImage}>
                <MdAddBox /> Dodaj ucięte zdjęcie
              </Button>
            </Col>
            <Col md={4} style={{ padding: 5 }}>
              <Button variant="primary" onClick={handleRotateClick}>
                <MdCropRotate /> Obróć
              </Button>
            </Col>
          </>
        ) : (
          <Col>
            <Form onChange={onSelectFile}>
              <Form.Group>
                <Form.Label>Wybierz zdjęcie z dysku</Form.Label>
                <Form.Control type="file" lang="pl" accept={VALID_TYPES.join(",")} />
              </Form.Group>
            </Form>
          </Col>
        )}
      </Row>
      {upImg && (
        <Row className="panel">
          <Col sm={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="form-photo-name">Nazwa</InputGroup.Text>
              <FormControl
                aria-describedby="form-photo-name"
                maxLength={20}
                value={photoName}
                onChange={(e: any) => setPhotoName(e.currentTarget.value)}
                required
                placeholder="np. KalendarzA4, magnes9x6, magnes15x10, notes"
              />
            </InputGroup>
          </Col>
          <Col sm={6}>
            <InputGroup className="mb-3">
              <InputGroup.Text id="form-photo-quantity">Liczba sztuk</InputGroup.Text>
              <FormControl
                aria-describedby="form-photo-quantity"
                maxLength={20}
                type="number"
                min={1}
                value={quantity}
                onChange={(e: any) => setQuantity(e.currentTarget.value)}
                required
              />
            </InputGroup>
          </Col>
        </Row>
      )}

      {upImg && (
        <ReactCrop
          src={upImg}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onLoad}
          onChange={c => setCrop(c)}
          onComplete={prepareCrop}
          keepSelection
          locked
        />
      )}

      {/* {previewUrl && <img alt="Crop preview" src={previewUrl} />} */}
    </div>
  );
}

export default React.memo(Cropper);
