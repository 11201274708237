import { sha1 } from "object-hash";
import React from "react";
import { Button, Table } from "react-bootstrap";
import * as appReducer from "reducers/useAppReducer";
import { FinalImage } from "types";

interface Props {
  appDispatch: React.Dispatch<appReducer.Action>;
  files: FinalImage[];
}

function FilesList({ appDispatch, files }: Props) {
  if (!files.length) {
    return null;
  }

  return (
    <div>
      <h3>Dodane zdjęcia</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Nazwa</th>
            <th>Liczba sztuk</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => (
            <tr key={sha1(file)}>
              <td>{index + 1}</td>
              <td>{file.photoName}</td>
              <td>{file.quantity}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => appDispatch({ payload: file.id, type: appReducer.DELETE_FILE })}
                >
                  Usuń
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default React.memo(FilesList);
