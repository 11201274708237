import React from "react";
import { Button, Col, FormControl, InputGroup, Row, Spinner } from "react-bootstrap";
import * as appReducer from "reducers/useAppReducer";

interface Props {
  appDispatch: React.Dispatch<appReducer.Action>;
  appState: appReducer.State;
  sendImages: () => Promise<void>;
}

function GeneralData({ appState: { files, login, surname }, appDispatch, sendImages }: Props) {
  const isSendingEnabled = React.useMemo(() => Boolean(files.length), [files.length, login, surname]);

  const isSendingImagesWorks = React.useMemo(
    () => Boolean(files.length && login && surname),
    [files.length, login, surname]
  );

  const [loginError, setLoginError] = React.useState(false);
  const [surnameError, setSurnameError] = React.useState(false);

  const showErrors = React.useCallback(() => {
    if (!login) {
      setLoginError(true);
    } else {
      setLoginError(false);
    }

    if (!surname) {
      setSurnameError(true);
    } else {
      setSurnameError(false);
    }
  }, [login, surname]);

  React.useEffect(() => {
    if (login) {
      setLoginError(false);
    }
  }, [login]);

  React.useEffect(() => {
    if (login) {
      setSurnameError(false);
    }
  }, [surname]);

  return (
    <Row className="justify-content-md-center">
      <Col md="auto">
        <InputGroup className="mb-3" hasValidation>
          <InputGroup.Text id="form-login-allegro">Login Allegro</InputGroup.Text>
          <FormControl
            aria-describedby="login-allegro"
            maxLength={30}
            value={login}
            onChange={(e: any) => appDispatch({ payload: e.currentTarget.value, type: appReducer.SET_LOGIN })}
            required
            isInvalid={isSendingEnabled && loginError}
          />
        </InputGroup>
        <InputGroup className="mb-3" hasValidation>
          <InputGroup.Text id="form-nazwisko">Nazwisko</InputGroup.Text>
          <FormControl
            aria-describedby="form-nazwisko"
            maxLength={30}
            value={surname}
            onChange={(e: any) => appDispatch({ payload: e.currentTarget.value, type: appReducer.SET_SURNAME })}
            isInvalid={isSendingEnabled && surnameError}
          />
        </InputGroup>
      </Col>
      <Col md="auto">
        <Button variant="success" disabled={!isSendingEnabled} onClick={isSendingImagesWorks ? sendImages : showErrors}>
          Wyślij wszystkie zdjęcia
        </Button>
      </Col>
    </Row>
  );
}

export default React.memo(GeneralData);
