import * as React from "react";
import { Container, Navbar } from "react-bootstrap";

const Header = () => {
  return (
    <header>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#">
            <img alt="" src="/logo192.png" width="30" height="30" className="d-inline-block align-top" /> Photo Cropper
          </Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
