import React from "react";
import { Card, Col, Row } from "react-bootstrap";

function Info() {
  return (
    <Row className="justify-content-md-center">
      <Col md={8} style={{ padding: 30 }}>
        <Card body>
          Kliknij na przycisk "Wybierz zdjęcie z dysku" i wybierz plik. Następnie ustaw ramkę tak aby uciąć zdjęcie. Po
          poprawnym umieszczeniu ramki, kliknij "Dodaj ucięte zdjęcie". Po dodaniu wymaganej liczby zdjęć oraz
          wprowadzeniu loginu z allegro i nazwiska kliknij "Wyślij wszystkie zdjęcia". W zależności od liczby zdjęć i
          prędkości internetu wysyłanie może potrwać nawet kilkadziesiąt minut.
        </Card>
      </Col>
    </Row>
  );
}

export default React.memo(Info);
