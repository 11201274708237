type Action<P> = (payload: P) => { payload: P };

// Simple action without payload defined
export function createAction<T extends string>(type: T): () => Readonly<{ type: T }>;
// Action with payload
export function createAction<T extends string, P>(type: T): (payload: P) => Readonly<{ payload: P; type: T }>;
// Action with custom action definition
export function createAction<T extends string, P>(
  type: T,
  action: Action<P>
): (payload?: P) => Readonly<{ payload: P; type: T }>;

export function createAction<T extends string, P>(type: T, action?: Action<P>) {
  if (typeof action !== "undefined") {
    return (payload: P) => ({
      ...action(payload),
      type,
    });
  }
  return (payload: P) => (typeof payload === "undefined" ? { type } : { payload, type });
}
